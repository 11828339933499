import './styles/App.css';
import ChatBot,{  ChatBotProvider, useToast,  RcbPreInjectMessageEvent,
   useSettings, useMessages } from "react-chatbotify";
import { ServiceRequest, ServiceResponse, loadContent, GetChatHistory } from './service/service';
import { _Settings, Styles } from './config/chat.settings'
import { uuidv4, cleanHtml } from './utils'
import {  useEffect, Suspense, useState } from 'react';
import { ConfigHook } from './service/configHook';
import { useTranslation } from "react-i18next";
import MediaPopoverContainer from './components/MediaPopoverContainer';
import Gallery from './components/Gallery';
import DataMarker from './components/DataMarker'
import Error from './config/error';


let user_id;

function App() {
  let errorHandler = null, requestId = null, answerOptions = null, answerContent=null;
  const { t, i18n } = useTranslation();
  const { messages } = useMessages();

  const [isPopoverActive, setPopoverActive] = useState(false);
  const [ imageSource, setImageSource ] = useState();
  const [ contentType, setContentType ] = useState();

  const handlePopoverOpen = (src, type) => {
    setPopoverActive(true);
    setImageSource(src);
    setContentType(type);
  };
  const handlePopoverClose = () => {
    setPopoverActive(false);
  };  
  
  
  useEffect(() => {
    console.log(messages)
    const handlePreInjectMessage = (event) => {
      if(event.data.message.type == 'object' && event.data.message.content.type.name == 'DataMarker') return;
      let ts = new Date(event.data.message.timestamp);
      let mn = ts.getMinutes();
      mn = mn < 10 ? `0${mn}` : mn;

      ts = `${ts.getHours()}:${mn}`;
      if( event.data.message.sender =='user'){
        let cleanUserInput = cleanHtml(event.data.message.content);
        if(cleanUserInput.dirty){
          event.preventDefault();
        }
        else event.data.message.content = cleanUserInput.cleanInput + "<div class='message-timestamp'>"+ ts +"</div>"
      }
      else {//bot bubble: add content and timestamp
        if(event.data.message.type == 'string' )  {
           event.data.message.content += "<div class='message-timestamp'>"+ ts +"</div>"
        }
        else { //menu 
          }
      }
    };

    window.addEventListener("rcb-pre-inject-message", handlePreInjectMessage);
    return () => {
      window.removeEventListener("rcb-pre-inject-message", handlePreInjectMessage);
    };

    
  }, [messages]);




  async function sendUserMessage( arg ) {
    try {
      console.log(`sendUserMessage ${arg.userInput}`)
      let cleanUserInput = cleanHtml(arg.userInput);
      if( cleanUserInput.dirty ){
        console.log(`sendUserMessage dirty`)
        errorHandler = Error.DANGEROUS_CODE;
      }
      else {
        let result = await ServiceRequest({ text: cleanUserInput.cleanInput });
        if( result && result.error ){
          errorHandler = result;
        }
        else {
            requestId = result
        }
      }
    } catch(err) {
      console.log(`error while waiting ServiceRequest: ${arg.userInput}`);
      errorHandler = Error.ERROR
    }
  }

  function getLoggedUser(){
    user_id = localStorage.getItem('known_user');
    if( !user_id ){
      let current_timestamp = new Date().getTime();
      user_id = uuidv4() + '_' + current_timestamp;
      localStorage.setItem('known_user', user_id);
      console.log(`new user: ${user_id}`)
    }
    else {
      console.log(`known user:  ${user_id}`)
    }
    return user_id;
  }


	const flow={
		start: {
			message: async(params) => {
         return t('welcome_message')
      },
			path: "dialog"
		},
 		dialog: {
			message: async (params) => {
        console.log(`dialog: message ${params.userInput}`)
       // await params.injectMessage(<DataMarker timestamp = { Date.now() } validate = { true } sender={'dialog'} input={params.userInput}/>, "user");
        //обнулить предыдущий запрос
        requestId = null;
        try {
          //запрос пользователя
          await sendUserMessage(params);
          if( errorHandler ) { return t(errorHandler.message) }
          
          //ожидание ответа
          console.log(`dialog: message requestId ${requestId}`)
          let res = await ServiceResponse({ requestId, params });
          if( res && res.error ) {
            if( res.message)  return t(res.message, res.key)
            if( res.error.message) return res.error.message;
            else return  t('error.get_answer')
          }

          //if has content
          if( res.content && res.content.length ){
            answerContent = res.content;
          }

          //answer has options
          if( res.options ){
            answerOptions = res.options;
          }

          //показываем ответ и ждем новый запрос
          return res.answer;

        } catch(err) {
          console.log(`error while waiting ServiceResponse: ${params.userInput}`);
          return t('error.get_answer')
        }
			},
      component: async() => {
        if( !answerContent ) return;
        let readyContent = await loadContent(answerContent);
        answerContent = null;
        return  <Gallery content = { readyContent } onClick = { handlePopoverOpen }/>
      },
      options: async() => {
        console.log(`OPTIONS: ${JSON.stringify(answerOptions)}`)
        return answerOptions ? answerOptions : [] 
      },
			path: () => {
        errorHandler = null;
        answerOptions = null;
        answerContent = null;
        return  "dialog"
      }
		}
	}
  
  return (
    <Suspense fallback={<div>Loading...</div>}>
    <ChatBotProvider>
      <ConfigHook handleMediaItemOpen = { handlePopoverOpen }/>
      <ChatBot  flow = { flow } settings= { _Settings } styles = { Styles } />
      {isPopoverActive && (<MediaPopoverContainer onClose = { handlePopoverClose } src = { imageSource } type = { contentType }/>)}
    </ChatBotProvider>
    </Suspense>
  );
}

export default App;
