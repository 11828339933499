function Options(props){
    const { items } = props;

    return (
        <div className="rcb-options-container rcb-options-offset">
          { items.map((item, index) => (
              <div className = "rcb-options options-button" key = { index }>{ item }</div>
            ))
          }
        </div>
    )
}

export default Options;