
//message field should contain translation code
const Error = {
    DANGEROUS_CODE: { error:true, code: 4, message:'error.dangerous'},
    SERVER_ERROR: {error:true, code: 1, message:`error.server`},
    ERROR: {error:true, code: 2, message:"error.error"},
    SERVER_REPLY_ERROR: {error:true, code: 3, message:"error.reply_error"},
    LOAD_CONTENT: {error: true, code: 5, message: 'error.load_content'},
    REQUEST_ID_ENDEFINED: { error: true, code:6, message: 'error.request_id_undefined'},
    REPLY_TIMEOUT: { error: true, code: 7, message: 'error.reply_timeout'},
    GET_HISTORY_ERROR: {error: true, code: 8, message: 'error.get_history_error'}
}

export default Error