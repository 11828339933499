import { useTranslation } from "react-i18next";
import { GetChatHistory } from "../service/service";
import { useMessages } from "react-chatbotify";
import  Gallery from './Gallery'
import {  useEffect, useState } from 'react';
import Options from './Options'
import DataMarker from './DataMarker'


function LoadHistory( props ){
    const { user_id, handleOpen } = props;
    const { t, i18n } = useTranslation();
    const { messages, replaceMessages, injectMessage } = useMessages();
    const [ showBtn, setShowBtn] = useState({display:'block'});
    const [ showProgress, setShowProgress ] = useState({ display:'none' });
    const [ showButtonUp,setShowButtonUp ] = useState({ display: 'block'});
    const [ noMoreHistory, setNoMoreHistory] = useState(false);
    const el = document.querySelector('.rcb-chat-body-container');

    function scrollHandler(){
        if( !el || noMoreHistory) return;
        if( el.scrollTop > 100 ) setShowBtn({ display:'none' })
        else setShowBtn({ display:'block' })
    }
    useEffect(() => {
        el && el.addEventListener("scroll", scrollHandler);
        return () => {
            el.removeEventListener("scroll", scrollHandler);
          };
    }, [])

    async function getHistory(){
        let timestamp = messages[0].timestamp;//получить сообщения старее чем первое сообщение в чате
        let count = null;                     //получить все сообщения
        let chat_history = await GetChatHistory({ user_id, timestamp, count });
        if( chat_history.error ) {
            injectMessage(t(chat_history.message));
            setShowProgress({ display: 'none'});
            setShowButtonUp({ display: 'block'});
            return;
        }

        let oldScrollHeight = el.scrollHeight
        let new_messages = [];

        //добавить дату в начало чата
        let first_date = new Date(chat_history.messages[0].timestamp);
        new_messages.push({
            id: chat_history.messages[0].timestamp + '_timestamp',
            content: <DataMarker timestamp = { first_date.toLocaleDateString() }/>,
            sender: 'user',
            timestamp: chat_history.messages[0].timestamp
         })

         //transform messages to Messages array
        chat_history.messages.forEach(( item, index, arr ) => {
            let ts = new Date(item.timestamp);
            let mn = ts.getMinutes();
            mn = mn < 10 ? `0${mn}` : mn;
            ts = `${ts.getHours()}:${mn}`;

            new_messages.push({
                id: index + item.timestamp +  '_id',//item.id,
                content:item.text + "<div class='message-timestamp'>"+ ts +"</div>",
                sender: item.sender,
                timestamp: item.timestamp
             })

             if( item.content ){
                new_messages.push({
                    id: item.id + '_content',
                    content:<Gallery content = { item.content } onClick = { handleOpen }/>,
                    sender: item.sender,
                    timestamp: item.timestamp 
                })
             }
             if( item.options ){
                new_messages.push({
                    id: item.id + '_options',
                    content: <Options items = { item.options }/>,
                    sender: item.sender,
                    timestamp: item.timestamp 
                })
             }
            //сравнить даты
            if(arr[index+1]){
                let ts1 = new Date(item.timestamp);
                let ts2 =  new Date(arr[index+1].timestamp);
                    if( ts1.getDate() != ts2.getDate() ) {
                        new_messages.push({
                            id: item.id + '__timestamp',
                            content: <DataMarker timestamp = { ts2.toLocaleDateString() }/>,
                            sender: 'user',
                            timestamp: arr[index+1].timestamp
                        })
                    }
            }
         })

         if( chat_history.first_request_timestamp ){
            let fm_timestamp = new Date(chat_history.first_request_timestamp).getTime();
            let chat_first_message_timestamp = new Date(new_messages[0].timestamp).getTime();
            if( fm_timestamp >= chat_first_message_timestamp ){
                //на сервере больше не осталось сообщений
                setNoMoreHistory(true);
                el.removeEventListener("scroll", scrollHandler);
            }
         }


         new_messages.push(...messages);
         replaceMessages(new_messages);
         setShowProgress({ display: 'none'});
         setShowButtonUp({ display: 'block'});

         setTimeout(() => { //scroll to current message
            let newScrollHeight =  el.scrollHeight;
            el.scrollBy(0,newScrollHeight-oldScrollHeight)
        }, 100)
     }

     function waitHistory(){
        setShowProgress({ display: 'flex'});
        setShowButtonUp({ display: 'none'});
        getHistory();
     }

     return(
        <> 
            { noMoreHistory ?
                (<></>) : 
                (<div className = "load-history-btn" style =  { showBtn }>
                    <div className = "tooltip">
                    <button style = { showButtonUp }
                    onClick = { waitHistory }/>
                    <span className = "tooltiptext">{t('load_chat_history')}</span>
                    </div>
                    <div className="rcb-typing-indicator load-history-loader" style = { showProgress }>
                        <span className="rcb-dot"></span><span className="rcb-dot"></span><span className="rcb-dot"></span>
                    </div>
                </div>)
            }
        </>
    )
}

export default LoadHistory;