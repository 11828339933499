import MediaItem from './MediaItem'

function MediaPopoverContainer(props){
    const { onClose, src, type } = props;

    return (
      <div className='media-popover-container' >
        <div className='chat-media' >
            <MediaItem src = { src } type = { type } />
        </div>
        <button type="button" className="close" style={{backgroundImage: 'url(./assets/close.svg)'}} onClick={onClose}></button>
      </div>
    )
}

export default MediaPopoverContainer