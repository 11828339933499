import { serverURL, Auth, ReplyTimeout } from '../config/config';
import  Error  from '../config/error'

//шаблон http запроса
//api - обязательный параметр
//data - body/query object
function appRequest({ api, method, content_type, data }){
   let options = {
        method: method || 'GET',
        headers: {
           'Content-Type': content_type || 'application/json',
           'Authorization': Auth
        }
    }
    if(  method && ( method == 'POST' || method == 'PUT')){
        options.body = data ? JSON.stringify(data) : null;
    }
    if( data && (!method || method == 'GET') ){
        let params = new URLSearchParams(data).toString();//`timestamp=${data.timestamp}&user_id=1`//
        api += `?${params}`
    }
    return fetch(serverURL + api, options)
}

const ServiceRequest = ({ text }) => {
  let data = {
    question: text,
    question_lang_code: "ru",
    answer_lang_code: "ru"
  };
 
  return appRequest({ api: '/chat/request', method: 'POST', data })
    .then(res => {
        console.log(`got response for /chat/request ${text}`);
        return res.json();
    }).then(res => {
       console.log(`/chat/request response parsed ${res.id}`)
       if( !res.id ) { return Error.REQUEST_ID_ENDEFINED }
      let request_id =  res.id;
      return request_id;
    }).catch(err => {
       console.log(`/chat/request error`);
       return Error.SERVER_ERROR;
  })
}
let i=0;

const ServiceResponse = ({ requestId, params }) => {
    console.log(`/chat/reply for ${requestId}`);
    let timeout_exceeded = trackReplyRequest( requestId );
    if( timeout_exceeded ) {
        let error = Error.REPLY_TIMEOUT;
        error.key = { reply_timeout: ReplyTimeout/1000 } //parameter for translation
        return error; 
    }

    return appRequest({api: '/chat/reply/'+ requestId  })
    .then(async res => {
        if( res && !res.ok){
            if( res.status == 404 ){//если ответa пока нет
               await new Promise(r => setTimeout(r, 500));
               return ServiceResponse({ requestId });
            }
            else {
                //другие ошибки
                console.log(`/chat/reply error.status = ${res.status}`)
                return Error.SERVER_REPLY_ERROR;
            }
        }
        else {
            return res.json().then(result => {
                console.log(`/chat/reply result ${JSON.stringify(result)}`)
                return result;
            })
        }
    }).catch(err => {
        console.log(`/chat/reply error ${JSON.stringify(err)}`)
        return Error.SERVER_REPLY_ERROR;
    })
  }


//getHistoryCounter - счетчик повторных запросов для получения истории
const GetChatHistory = ({ user_id, timestamp, count, getHistoryCounter = 0 }) => {
    let data = { user_id, timestamp };
    console.log(`timestamp iso string ${new Date(timestamp).toISOString()}` )
    data.timestamp = new Date(timestamp).toISOString();
    if( count ) data.count = count;
    return appRequest({ api: '/chat/history', data })
      .then(async result => {
            if( !result ) { return Error.GET_HISTORY_ERROR }
            if ( !result.ok && getHistoryCounter < 10){
                getHistoryCounter++;
                await new Promise(r => setTimeout(r, 1000));
                return GetChatHistory({ user_id, timestamp, count, getHistoryCounter })
            }
            result = await result.json();
            console.log(`/chat/history response parsed ${JSON.stringify(result)}`)
            let content = [], unique_ids = [];
            result.messages.forEach(message => {
                if( message.content ){
                    message.content.forEach( media => {
                        if( !unique_ids.includes(media.content_id ))
                            content.push(media)
                    })
                }
            })
            if( content.length ){
            return loadContent(content).then(res => {
                let res_obj = {};
                res.forEach(r => { res_obj[r.content_id] = r })
                result.messages.forEach(r => {
                    r.content && r.content.forEach((c,i) => { r.content[i] = res_obj[c.content_id]})
                });
                return result;
            })
        } else {
            return result;
        }
      }).catch(err => {
         console.log(`/chat/history error`);
         return Error.SERVER_ERROR;
    })
  }


  // getContentCounter - счетчик повторных запросов для каждого content_id
  function addContent( {content_id, file_type, content_type, title}, url_path, getContentCounter = 0 ){
    return appRequest({ api: `/chat/content/${content_id}`, content_type: 'blob'})
    // return fetch('http://localhost:3000/assets/'+url_path, { contentType: 'blob' })
    .then(async res => {
        if( !res.ok ){
            console.log(`wait content ${content_id}`)
            if(  getContentCounter < 60 ){
                await new Promise(r => setTimeout(r, 1000));
                getContentCounter++;
                return addContent( content_id, url_path, getContentCounter )
            }
            else {
                console.log(`could not receive content ${content_id}  title = ${title}`);
                return Error.LOAD_CONTENT;
            }
        }
        else {
            console.log(`got content ${content_id}`)
            return res.blob();
        }
    })
    .then(blob => {
        if( !blob || blob.error ){
            return {blob: {type: 'error'}, url: null, title: ''};
        }
        let reader = new FileReader();
       
        return new Promise( (resolve,rej)=>{
            reader.readAsDataURL(blob); // конвертирует Blob в base64 и вызывает onload
            reader.onload = function() {
               resolve({ blob, url:  reader.result , content_id, title});
           };
       })
    });
  }

  //change to get content for requestId
  const loadContent = async (content, requestId) => {
   let promises = [];
   content.forEach(item => {
        promises.push(addContent(item, item.url_path));
   });

    return Promise.all(promises)
    .then(res => {
        return res;
    }).catch(err => {
        console.log(`error content loading`);
        return Error.LOAD_CONTENT;
    })

 }
 

let replyRequestMonitor = {};
function trackReplyRequest( id ){
    let timeout_exeeded = true;
    if( !replyRequestMonitor[id] ){
        replyRequestMonitor[id] = new Date().getTime();
        return !timeout_exeeded;
    }
    else {
        timeout_exeeded = new Date().getTime() - replyRequestMonitor[id] > ReplyTimeout;
        if( timeout_exeeded ) { delete replyRequestMonitor[id] }
        return timeout_exeeded;
    }
}

export { ServiceRequest, ServiceResponse, loadContent, GetChatHistory }
