// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/TTFirsNeue-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/TTFirsNeue-Medium.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "TTFirsNeue-Regular";
  src: local("TTFirsNeue-Regular"), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}
@font-face {
  font-family: "TTFirsNeue-Medium";
  src: local("TTFirsNeue-Medium"), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}

body {
  margin: 0;
  font-family: "TTFirsNeue-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "TTFirsNeue-Regular";
}
body * {
    font-family: "TTFirsNeue-Regular";
 }
`, "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,4FAA2F;AAC7F;AACA;EACE,gCAAgC;EAChC,2FAAyF;AAC3F;;AAEA;EACE,SAAS;EACT,iCAAiC;EACjC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,iCAAiC;AACnC;AACA;IACI,iCAAiC;CACpC","sourcesContent":["@font-face {\n  font-family: \"TTFirsNeue-Regular\";\n  src: local(\"TTFirsNeue-Regular\"), url(\"../fonts/TTFirsNeue-Regular.ttf\") format(\"truetype\");\n}\n@font-face {\n  font-family: \"TTFirsNeue-Medium\";\n  src: local(\"TTFirsNeue-Medium\"), url(\"../fonts/TTFirsNeue-Medium.ttf\") format(\"truetype\");\n}\n\nbody {\n  margin: 0;\n  font-family: \"TTFirsNeue-Regular\";\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: \"TTFirsNeue-Regular\";\n}\nbody * {\n    font-family: \"TTFirsNeue-Regular\";\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
