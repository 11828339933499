import MediaItem from './MediaItem'

 function Gallery(props){
    const { content, onClick } = props;
    let columns = [];
    if( content && content.length && content.length > 1 ){
          let column_length = Math.trunc(content.length/2);
          columns[0] = content.slice(0,column_length );
          columns[1] = content.slice(column_length);
    }

    return (
        <div className="rcb-bot-message rcb-bot-message-entry bot-message">
            <div className='message-media-wrapper'>
                {content && content.length == 1 ?
                    (
                       <MediaItem
                           type = { content[0].blob.type } src = { content[0].url } title = { content[0].title }
                           class1 = 'single-content-image'
                           onClick = {() => { onClick(content[0].url, content[0].blob.type) }}/>
                    ) :
                    (
                       <>
                         <div className="message-media-column">
                            { columns[0].map((element, index) => (
                                <MediaItem
                                    type = { element.blob.type }
                                    key = { index } //this key is required by react
                                    ikey = { index }
                                    src = { element.url }
                                    title = { element.title }
                                    class1 = 'content-image'
                                    onClick = { () => { onClick(element.url, element.blob.type) }}/>
                              )) }
                         </div>
                         <div className="message-media-column">
                            { columns[1].map((element, index) => (
                                <MediaItem
                                    type = { element.blob.type }
                                    key = { index }
                                    ikey = { index }
                                    src = { element.url }
                                    title = { element.title }
                                    class1 = 'content-image'
                                    onClick = { ()=>{ onClick(element.url, element.blob.type) }}/>
                              )) }
                         </div>
                       </>
                    )
                }
            </div>
        </div>
    )
}

export default Gallery