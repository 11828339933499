import { useEffect  } from "react";
import {  useSettings, useChatWindow } from "react-chatbotify";
import { useTranslation } from 'react-i18next';
import LoadHistory from '../components/LoadHistory';
import LanguageSelectorButton from '../components/LanguageSelectorButton';


const ConfigHook = (props) => {
  const {  updateSettings } = useSettings();
  const { t, i18n } = useTranslation();
  const { isChatWindowOpen } = useChatWindow();
  const { handleMediaItemOpen } = props;
  
  useEffect(() => {
    updateChatSettings();
  }, [isChatWindowOpen])

  
  const changeLanguage = (lng) => {
    return i18n.changeLanguage(lng).then(() =>{
        return updateChatSettings();
    })
  };
  
  function updateChatSettings(){
    return updateSettings({
        chatInput: { enabledPlaceholderText: t('input_placeholder') },
        chatHistory: {
          viewChatHistoryButtonText: t('load_chat_history'),
          chatHistoryLineBreakText: t('chat_history_line_break_text')
        },
        header: {
          buttons: [<LanguageSelectorButton/>,<LoadHistory handleOpen = { handleMediaItemOpen } />]
       }
    })
  }
 
  
  return (
    <>
      <button className="language-button" type="button" onClick={() => changeLanguage('ru')}>Русский</button>
      <button className="language-button" type="button" onClick={() => changeLanguage('en')}>English</button>
    </>
  )
 
  
 
  };

  export { ConfigHook}