import i18n from "i18next";
import {  initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import { DefaultLanguage } from "./config/config";


i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: DefaultLanguage,
    interpolation: {
      escapeValue: false 
    },
    debug: true
  }).then(() => {
    //initialize chatSettings
    
    
  });

  export default i18n;